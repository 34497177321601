<template>
  <div class="the_sidebar bg-white pt-2 pb-6 fixed">
    <div class="flex items-center pl-6 pr-6">

      <img src="../../assets/images/png/logo.png" class="h-24" alt="">

<!--      <div class="w-1 h-10 bord ml-2"/>-->
<!--      <div class="font-c7 text-c17 text-principal ml-2">GESTION</div>-->
    </div>


    <div class="defilOption pb-10 mt-6">

      <div class="" v-if="trackRole('Tableau de bord')">
        <menu-element
            menu="Tableau de bord"
            :icon="icons.dashboard"
            path="dashboard"
        />
      </div>

      <div class="mt-6 pl-10 pr-6">
        <div class="text-left text-c13 text-black">
          CLIENTÈLE
        </div>
      </div>


      <div class="mt-6">
        <div class="mt-2">
          <menu-element
              v-if="trackRole('Commerces')"
            menu="Entreprises"
            :icon="icons.commerce"
            path="commerce"
          />
        </div>

        <div class="pt-2">
          <menu-element
              v-if="trackRole('Coupons')"
              menu="Coupons"
              :icon="icons.coupon"
              path="coupons"
          />
        </div>

<!--        <div class="pt-2">-->
<!--          <menu-element-->
<!--              v-if="trackRole('Support client')"-->
<!--            menu="Support client"-->
<!--            :icon="icons.support"-->
<!--            path="support"-->
<!--          />-->
<!--        </div>-->
      </div>

      <div class="mt-6 pl-10 pr-6">
        <div class="text-left text-c13 text-black">
          ADMINISTRATION
        </div>
      </div>


      <div class="mt-6">
        <div class="mt-2">
          <menu-element
              v-if="trackRole('Commerciaux')"
              menu="Commerciaux"
              :icon="icons.commerciaux"
              path="commerciaux"
          />
        </div>

        <div class="pt-2">
          <menu-element
              v-if="trackRole('SFD partenaires')"
              menu="Programme"
              :icon="icons.sfd"
              path="partenaires"
          />
        </div>

        <div class="pt-2">
          <menu-element
              v-if="trackRole('Commerces')"
              menu="Demandes DGI"
              :icon="icons.commerciaux"
              path="demandes"
          />
        </div>

        <div class="pt-2">
          <menu-element
              v-if="trackRole('Droits d’accès')"
              menu="Droits d’accès"
              :icon="icons.droit"
              path="droits"
          />
        </div>

        <div class="flex justify-end pr-10 pt-10">
          <img src="../../assets/images/jpg/logoP.jpeg" class="h-16 mt-10 ml-10" alt="">
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import menuElement from './side-menu-element'
import dashboard from '../../assets/icons/dashboard.svg'
import logo from '../../assets/icons/whiteLogo.svg'
import user from '../../assets/icons/userS.svg'
import savo from '../../assets/icons/sav.svg'
import agenda from '../../assets/icons/agenda.svg'
import article from '../../assets/icons/article.svg'
import coupon from '../../assets/icons/ic-coupon.svg'
import support from '../../assets/icons/ic-support.svg'
import sfd from '../../assets/icons/ic-sfd.svg'
import commerciaux from '../../assets/icons/ic-commerciaux.svg'
import droit from '../../assets/icons/ic-droit.svg'
import commerce from '../../assets/icons/ic-commerce.svg'

export default {
  name: 'TheSidebar',
  components: {
    menuElement,
  },
  data () {
    return {
      icons: {
        commerce,
        logo,
        dashboard,
        user,
        savo,
        agenda,
        article,
        coupon,
        support,
        sfd,
        commerciaux,
        droit
      },
      user: null,
      allRole: [],
    }
  },
  mounted () {
    if (this.$store.getters.token !== null) {
      this.user = this.$store.getters.trackUser
      if (this.user.user.userType === 'ADMIN') {
        // console.log(this.user.role)
        this.allRole = this.user.role
        // this.allRole = ['Tableau de bord', 'Commerces', 'Coupons', 'Support client', 'Commerciaux', 'SFD partenaires', 'Droits d’accès']
      }

      if (this.user.user.userType === 'SFDADMIN') {
        this.allRole = this.user.role
        // console.log(this.user.role)
      }

      if (this.user.user.userType === 'SFDCOMMERCIAL') {
        this.allRole = ['Coupons']
      }
    }
  },
  methods: {
    accueil () {
      this.$router.go('/clients')
    },

    trackRole (index) {
      if (this.allRole.indexOf(index) !== -1) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/sass/variables';
.the_sidebar{
  height: 100vh;
  border-right: 1px solid #EAEAEF;
}
.defilOption{
  height: 100%;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.defilOption::-webkit-scrollbar {
  display: none;
}
.svg-icon {
  fill: white;
}
.bord {
  background-color: #4E6788;
}
</style>
